header .contact_info {
  height: 3.25rem;
  background-color: #1a1a1a;
  display: flex;
  justify-content: flex-end;
}

header .contact_info .phone {
  display: block;
  height: inherit;
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.19px;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
}

header .contact_info .phone span {
  margin-right: 13px;
  text-transform: uppercase;
}

header .contact_info .slash {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #e60000;
  margin-right: 1rem;
  margin-left: 1rem;
}

header .tabs_slot {
  display: flex;
  height: 5.625rem;
  border-bottom: 1px solid #dedede;
  background-color: #ffffff;
  text-transform: uppercase;
}

header .tabs_slot .logo_containner {
  width: 18rem;
  height: 9.125rem;
  position: relative;
  margin-left: 6rem;
  bottom: 3.25rem;
  background-color: #e60000;
  transform: skewX(-25deg);
  padding: 0 3.375rem 0 3.375rem;
  text-align: center;
}

header .tabs_slot .logo_containner img {
  transform: skewX(25deg);
  /* width: 11.3125rem; */
  height: 6.75rem;
  position: relative;
  top: 1.125rem;
  /* left: 3.375rem; */
}

header .tabs_slot .tab_slot {
  transform: skewX(-25deg);
  padding: 0 1.625rem;
  float: left;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.625rem;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.18px;
  text-align: center;
  color: #000000;
  border-right: 1px solid #dedede;
  transition: 1s;
  cursor: pointer;
}

header .tabs_slot .tab_slot.first {
  margin-left: -0.855rem;
}

header .tabs_slot .tab_slot.active {
  background-color: #dedede;
  height: 5.7rem;
}

header .tabs_slot .tab_slot span {
  transform: skewX(25deg);
  display: block;
}

header .tabs_slot .weather_block {
  display: flex;
  align-items: center;
  margin-right: 2.1rem;
  height: 5.625rem;
  /* font-family: KievitOT; */
  font-size: 2.1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.34px;
  text-align: left;
  color: #1a1a1a;
}
header .tabs_slot .weather_block .weather_info {
  display: flex;
  align-items: flex-start;
}
header .tabs_slot .weather_block svg {
  display: block;
  float: left;
  width: 2.625rem;
  height: 2.5rem;
  position: relative;
  margin-right: 1rem;
}
header .tabs_slot .weather_block span.title {
  font-size: 2.5rem;
}
header .tabs_slot .weather_block span.meausure {
  font-size: 2rem;
  margin-top: auto;
  position: relative;
  bottom: -0.3rem;
}
header .tabs_slot .weather_block span.point {
  font-size: 2rem;
  position: relative;
  top: -0.3rem;
}
header .tabs_slot .weather_partners {
  margin-left: auto;
  display: flex;
}
header .tabs_slot .biss_data_block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.625rem;
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
  padding: 0 2.8125rem;
  transform: skewX(-25deg);
  margin-right: 2.5rem;
}
header .tabs_slot .biss_data_block img {
  max-height: 3.75rem;
  max-width: 9.375rem;
  /* width: 100%; */
  transform: skewX(25deg);
}
@media screen and (max-width: 1367px) {
  header .tabs_slot .tab_slot:nth-child(5) {
    border-right: unset;
  }
}
