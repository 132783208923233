@keyframes thirdTabShow {
    0% {
        display: block;
        opacity: 0;
    }
    66% {
        display: block;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes thirdTabhidde {
    0% {
        display: block;
        opacity: 1;
    }
    99% {
        display: block;
        opacity: 0;
    }
    100% {
        display: none;
        opacity: 0;
    }
}

.page_containner .third_tab.hide {
    animation: thirdTabhidde 1s forwards;
}

.page_containner .third_tab.show {
    animation: thirdTabShow 1.5s forwards;
}

.page_containner .third_tab.start {
    opacity: 0;
    display: none;
}

.page_containner .third_tab {
    background-color: black;
}

.page_containner .third_tab .videoBlock {
    width: 87.75rem;
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    /* left: calc(50% - 43.875rem); */
    /* top: calc(50% - 24.734375rem); */
}
