html,
body {
  font-size: 1vw;
}
body,
#mount-point {
  margin: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: block;
  font-family: "kievitot_bold";
}
p {
  margin: 0;
}
