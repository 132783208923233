.page_containner .second_tab.hide {
  animation: page_hidden 1s forwards;
}

.page_containner .second_tab.show {
  animation: page_show 1.5s forwards;
}

.page_containner .second_tab .hidder.show {
  animation: hidder_show 1.5s forwards;
}

.page_containner .second_tab .hidder.hide {
  animation: hidder_hidden 1s forwards;
}

.page_containner .second_tab .image_block.show {
  animation: image_show 1.5s forwards;
}

.page_containner .second_tab .image_block.hide {
  animation: image_hidde 1s forwards;
}

.page_containner .second_tab .hidder {
  width: 81%;
  height: 100%;
  position: absolute;
  left: 24%;
  transform: skewX(-25deg);
  overflow: hidden;
  background-color: white;
  z-index: 2;
}
.page_containner .second_tab .image_block {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 76%;
  overflow: hidden;
  transform: skewX(-25deg) scaleX(1);
  background: white;
  transform-origin: 100% 50%;
}

.page_containner .second_tab img {
  width: auto;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  z-index: 0;
}

.page_containner .second_tab .content_block {
  width: 81%;
  height: 100%;
  position: absolute;
  left: 17.55rem;
  transform: skewX(-25deg);
  overflow: hidden;
  background-color: white;
  /**/
  transform-origin: 0% 50%;
}

.page_containner .second_tab .content_block .paralax_block {
  position: relative;
  height: 100%;
  width: 48.8125rem;
  transform: skewX(25deg);
  left: calc(50% - 24.40625rem);
  padding-top: 68px;
}

.page_containner .second_tab .content_block .paralax_block .date {
  text-align: left;
  font-size: 3.4375rem;
  box-sizing: border-box;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  color: #1a1a1a;
  margin-bottom: 1.6rem;
}

.page_containner .second_tab .content_block .paralax_block .event_block {
  width: 46.25rem;
  /* height: 114px; */
  margin-bottom: 1.25rem;
  font-family: NotesStyleNbr-Brand;
  font-size: 3rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  box-sizing: border-box;
  line-height: normal;
  letter-spacing: -0.6px;
  color: #ffffff;
  position: relative;
  background-size: cover;
  padding: 1rem 0;
}
.page_containner
  .second_tab
  .content_block
  .paralax_block
  .event_block
  .filter {
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: 0;
  background-color: rgba(255, 0, 0, 0.75);
}
.page_containner .second_tab .content_block .paralax_block .event_block p {
  position: relative;
  margin-left: 2.5rem;
  margin-bottom: 0.75rem;
  z-index: 2;
}
.page_containner
  .second_tab
  .content_block
  .paralax_block
  .event_block
  p.event_place {
  font-size: 2.625rem;
  letter-spacing: -0.42px;
}

@media screen and (max-width: 1900px) {
  .page_containner .second_tab .content_block .paralax_block {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 1367px) {
  .page_containner .second_tab .content_block .paralax_block {
    padding-top: 2.5rem;
  }
}

@media screen and (max-width: 1281px) {
  .page_containner .second_tab .content_block .paralax_block {
    padding-top: 1.25rem;
  }
}
