@keyframes page_show {
  65% {
    z-index: -100;
  }
  66% {
    z-index: 100;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes page_hidden {
  0% {
    z-index: 200;
    opacity: 1;
  }
  99.9% {
    z-index: 200;
  }
  100% {
    z-index: -100;
    opacity: 0;
  }
}

@keyframes hidder_show {
  66% {
    left: 24%;
  }
  100% {
    left: 120%;
  }
}

@keyframes hidder_hidden {
  0% {
    left: 120%;
  }
  100% {
    left: 24%;
  }
}

@keyframes image_show {
  66% {
    right: 76%;
  }
  100% {
    right: 120%;
  }
}

@keyframes image_hidde {
  0% {
    right: 120%;
  }
  100% {
    right: 76%;
  }
}

.page_containner .first_tab.hide {
  animation: page_hidden 1s forwards;
}

.page_containner .first_tab.show {
  animation: page_show 1.5s forwards;
}

.page_containner .first_tab .hidder.show {
  animation: hidder_show 1.5s forwards;
}

.page_containner .first_tab .hidder.hide {
  animation: hidder_hidden 1s forwards;
}

.page_containner .first_tab .image_block.show {
  animation: image_show 1.5s forwards;
}

.page_containner .first_tab .image_block.hide {
  animation: image_hidde 1s forwards;
}

.page_containner .first_tab.show {
  z-index: 100;
}
.page_containner .second_tab.show {
  z-index: 100;
}

.page_containner .first_tab.start {
  z-index: -100;
}
.page_containner .second_tab.start {
  z-index: -100;
}

.page_containner .first_tab .image_block {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 76%;
  overflow: hidden;
  transform: skewX(-25deg) scaleX(1);
  transform-origin: 100% 50%;
  background-color: white;
}

.page_containner .first_tab img {
  width: auto;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  z-index: 0;
}

.page_containner .first_tab .hidder {
  width: 81%;
  height: 100%;
  position: absolute;
  left: 24%;
  transform: skewX(-25deg);
  overflow: hidden;
  background-color: white;
  z-index: 2;
}

.page_containner .first_tab .content_block {
  width: 81%;
  height: 100%;
  position: absolute;
  left: 17.55rem;
  transform: skewX(-25deg);
  overflow: hidden;
  background-color: white;
  /**/
  transform-origin: 0% 50%;
}

.page_containner .first_tab .content_block .paralax_block {
  position: relative;
  height: 100%;
  width: 42rem;
  transform: skewX(25deg);
  left: calc(50% - 21rem);
  padding-top: 1rem;
}

.page_containner .first_tab .content_block .paralax_block .date {
  text-align: left;
  font-size: 3.4375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.3.4375rem;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
}

.page_containner .first_tab .content_block .paralax_block .city_block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 5.625rem;
  width: 39.375rem;
  border-top: 1px solid #dedede;
  box-sizing: border-box;
  font-family: KievitOT;
  font-stretch: normal;
  font-style: normal;
  /*line-height: 5.625rem;*/
  padding-right: 2.9375rem;
  letter-spacing: normal;
  color: #1a1a1a;
  position: relative;
}

.page_containner .first_tab .content_block .paralax_block .border {
  width: 39.375rem;
  height: 1px;
  background-color: #dedede;
}

.page_containner .first_tab .content_block .paralax_block .city_block .status {
  width: 3.125rem;
  height: 3.125rem;
  float: left;
  border-radius: 50%;
}

.page_containner
  .first_tab
  .content_block
  .paralax_block
  .city_block
  .status.green {
  background-color: #3dc718;
}

.page_containner
  .first_tab
  .content_block
  .paralax_block
  .city_block
  .status.close {
  background-color: #e60000;
}

.page_containner .first_tab .content_block .paralax_block .city_block .name {
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
  font-size: 1.7rem;
  font-weight: normal;
}

.page_containner
  .first_tab
  .content_block
  .paralax_block
  .city_block
  .open_date {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 7.5rem;
  margin-left: auto;
  font-size: 1.125rem;
  font-weight: bold;
}
.page_containner
  .first_tab
  .content_block
  .paralax_block
  .city_block
  .open_date
  span {
  display: flex;
}

.page_containner
  .first_tab
  .content_block
  .paralax_block
  .title_containner::after {
  content: "";
  clear: both;
  display: table;
}

.page_containner
  .first_tab
  .content_block
  .paralax_block
  .title_containner
  .table_t {
  font-size: 0.9rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  color: #bfbfbf;
  position: relative;
  text-transform: uppercase;
}

.page_containner
  .first_tab
  .content_block
  .paralax_block
  .title_containner
  .location_title {
  float: left;
}

.page_containner
  .first_tab
  .content_block
  .paralax_block
  .title_containner
  .open_hours_title {
  float: right;
  width: 6.875rem;
  margin-right: 5.0625rem;
}

@media screen and (max-width: 1900px) {
  .page_containner .first_tab .content_block .paralax_block {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 1367px) {
  .page_containner .first_tab .content_block .paralax_block {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 1281px) {
  .page_containner .first_tab .content_block .paralax_block {
    padding-top: 20px;
  }
}
