@font-face {
    font-family: kievitot_bold;
    src: url('./Fonts/kievitot/kievitot_bold/kievitot_bold.eot'); /* IE9 Compat Modes */
    src: url('./Fonts/kievitot/kievitot_bold/kievitot_bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./Fonts/kievitot/kievitot_bold/kievitot_bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('./Fonts/kievitot/kievitot_bold/kievitot_bold.woff') format('woff'), /* Pretty Modern Browsers */
    url('./Fonts/kievitot/kievitot_bold/kievitot_bold.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('./Fonts/kievitot/kievitot_bold/kievitot_bold.svg#kievitot_bold') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: kievitot_regular;
    src: url('./Fonts/kievitot/kievitot_regular/kievitot_regular.eot'); /* IE9 Compat Modes */
    src: url('./Fonts/kievitot/kievitot_regular/kievitot_regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./Fonts/kievitot/kievitot_regular/kievitot_regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./Fonts/kievitot/kievitot_regular/kievitot_regular.woff') format('woff'), /* Pretty Modern Browsers */
    url('./Fonts/kievitot/kievitot_regular/kievitot_regular.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('./Fonts/kievitot/kievitot_regular/kievitot_regular.svg#kievitot_regular') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: notesstylenbr_brand;
    src: url('./Fonts/notesstylenbr_brand/notesstylenbr_brand.eot'); /* IE9 Compat Modes */
    src: url('./Fonts/notesstylenbr_brand/notesstylenbr_brand.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./Fonts/notesstylenbr_brand/notesstylenbr_brand.woff2') format('woff2'), /* Super Modern Browsers */
    url('./Fonts/notesstylenbr_brand/notesstylenbr_brand.woff') format('woff'), /* Pretty Modern Browsers */
    url('./Fonts/notesstylenbr_brand/notesstylenbr_brand.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('./Fonts/notesstylenbr_brand/notesstylenbr_brand.svg#notesstylenbr_brand') format('svg'); /* Legacy iOS */
}
.kievitot_bold {
    font-family: kievitot_bold;
}
.kievitot_regular {
    font-family: kievitot_regular;
}
.notesstylenbr_brand {
    font-family: notesstylenbr_brand;
}