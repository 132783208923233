.page_containner .fours_tab.hide {
  animation: thirdTabhidde 1s forwards;
}

.page_containner .fours_tab.show {
  animation: thirdTabShow 1.5s forwards;
}

.page_containner .fours_tab.start {
  opacity: 0;
  display: none;
}
.page_containner .fours_tab {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page_containner .fours_tab .page_title {
  margin-top: 3rem;

  font-size: 5.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 2.625rem;
}

.page_containner .cams_wrapper {
  display: flex;
  justify-content: space-around;
  width: 75%;
}

.page_containner .fours_tab .cam_block {
  width: 34.375rem;
  position: relative;
}

.page_containner .fours_tab .cam_block .status_block {
  float: left;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.page_containner .fours_tab .cam_block .status_block.opened {
  background-color: #3dc718;
}

.page_containner .fours_tab .cam_block .status_block.closed {
  background-color: #e60000;
}

.page_containner .fours_tab .cam_block .named {
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3rem;
  letter-spacing: normal;
  color: #000000;
  margin-left: 1.625rem;
}

.page_containner .fours_tab .cam_block .block_img {
  width: 34.375rem;
  height: 3rem;
  margin-top: 1.25rem;
  cursor: all-scroll;
  background-size: cover;
  background-position: 50% 0;

  transition: background-position 1s linear;
  padding-bottom: 30%;
}

.page_containner .fours_tab .cam_block .subName {
  font-size: 2.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1a1a;
  margin-top: 1.75rem;
}

.page_containner .fours_tab .cam_block .descript {
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1a1a;
  margin-top: 1.125rem;
}

/* @media screen and (max-width: 1900px) {
  .page_containner .fours_tab.show {
    zoom: 0.8;
  }
}
@media screen and (max-width: 1367px) {
  .page_containner .fours_tab.show {
    zoom: 0.68;
  }
}

@media screen and (max-width: 1281px) {
  .page_containner .fours_tab.show {
    zoom: 0.6;
  }
} */
