.page_containner {
  width: 100%;
  height: calc(100vh - 8.9375rem);
  overflow: hidden;
  position: absolute;
}
.page_containner div.child {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
}
/* .page_containner div.child.show{
    z-index: 2;
} */

@media screen and (max-width: 1281px) {
  .page_containner {
    height: calc(100vh - 8rem);
  }
}
